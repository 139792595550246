import { graphql, useStaticQuery } from "gatsby"

const useConcepts = () => {
  const data = useStaticQuery(graphql`
    {
      wordPress {
        concepts(first: 100) {
          nodes {
            content(format: RENDERED)
            conceptCF {
              description
              type
              cover {
                mediaItemUrl
              }
            }
            slug
            title(format: RENDERED)
            databaseId
            id
            uri
          }
        }
      }
    }
  `)

  return data.wordPress.concepts.nodes
}

export default useConcepts
