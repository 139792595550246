import { graphql, useStaticQuery } from "gatsby"

const useInformation = () => {
  const data = useStaticQuery(graphql`
    {
      wordPress {
        pages(
          where: { title: "Документация по планировке территории" }
          first: 1
        ) {
          nodes {
            content
            title
          }
        }
      }
    }
  `)

  return data.wordPress.pages.nodes[0]
}

export default useInformation
